export const deleteAccount = {
    title: 'Solicitud de eliminación de cuenta',
    info: {
        1: 'Si compraste tu suscripción a través de una compra dentro de la aplicación, deberás cancelarla manualmente en la configuración de suscripción de tu tienda de aplicaciones. De lo contrario, los pagos permanecerán activos y se le seguirá cobrando.',
        2: 'Si has comprado tu suscripción a través de otros medios y quieres cancelarla, ponte en contacto con el servicio de atención al cliente.',
        3: 'Debe incluir la dirección de correo electrónico vinculada a su cuenta digital: solo completamos las solicitudes con la información vinculada a su dirección de correo electrónico.',
        4: 'Para verificar su identidad, le enviaremos un correo electrónico a la dirección de correo electrónico que proporcione que coincida con nuestros registros y esperaremos su respuesta. En algunos casos, también podemos solicitar información adicional para asegurarnos de verificar su identidad antes de cumplir.',
        5: 'Para obtener más información sobre cómo manejamos sus datos, lea nuestra',
        policyPrivacy: 'Política de privacidad.'
    },
    form: {
        name: 'Tu nombre',
        email: 'Correo electrónico de la cuenta',
        phone: 'Número de teléfono de contacto',
        requestDetails: 'Detalles de la solicitud',
        submit: 'Enviar'
    },
    seo: {
        title: 'Solicitud de eliminación de cuenta - Be Soul'
    }
};
