import home from './home';
import common from './common';
import digitalLegacy from './digitalLegacy';
import plans from './plans';
import termsConditions from './termsConditions';
import privacyPolicy from './privacyPolicy';
import memorials from './memorials';
import calm from './calm';
import support from './support';
import comingSoon from './comingSoon';
import gallery from './components/gallery';
import memorial from './components/memorial';
import login from './login';
import signUp from './signUp';
import createMemorialAssist from './createMemorialAssist';
import { deleteAccount } from './deleteAccount/es';

const es = {
    home,
    common,
    digitalLegacy,
    plans,
    termsConditions,
    privacyPolicy,
    memorials,
    calm,
    support,
    comingSoon,
    components: {
        gallery,
        memorial
    },
    login,
    signUp,
    createMemorialAssist,
    deleteAccount
};

export default es;
export type Translations = typeof es;
