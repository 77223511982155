const common = {
    header: {
        legacy: 'Legados',
        memorials: 'Memoriales',
        calm: 'Calma',
        plans: 'Planes',
        about: 'Acerca de',
        us: 'Nosotros',
        blog: 'Blog',
        termsAndCondition: 'Términos y condiciones',
        languages: {
            spanish: 'Español',
            english: 'Inglés'
        },
        create: 'Crear',
        login: 'Iniciar sesión',
        signUp: 'Registro',
        logout: 'Cerrar sesión'
    },
    footer: {
        contactUs: 'Contáctanos',
        aboutUs: 'Sobre nosotros',
        plans: 'Planes',
        siteOne: 'Sitio Uno',
        moreInformation: 'Más información',
        betaProgram: 'Programa Beta',
        privacyPolicy: 'Políticas de privacidad',
        termsAndConditions: 'Términos y Condiciones',
        cookiesPolicy: 'Políticas de Cookies',
        followUs: 'Síguenos',
        copyright1: 'Creado por Sitio Uno, Inc.',
        copyright2: 'Todos los derechos reservados'
    },
    downloadApp: {
        title: 'Comienza A Construir Tu Legado Digital',
        subtitle: '¡Descarga ahora!',
        seo: {
            title: 'Descarga ahora | Be Soul App',
            description:
                'Elevate con la aplicación Be Soul. Descárgala ahora en App Store y Play Store para vivir momentos transformadores, compartir sin interrupciones y crear recuerdos eternos.'
        }
    },
    errors: {
        phoneAlreadyUse: 'El teléfono ya está en uso.',
        emailAlreadyUse: 'Correo electrónico ya está en uso.',
        invalidCredentials: 'Credenciales no inválidas.',
        genericError: 'Algo salió mal, intenta de nuevo.'
    },
    success: {
        copied: 'Copiado',
        fileDownloaded: 'Archivo descargado'
    },
    chip: {
        required: 'Requerido',
        todo: 'Por completar',
        completed: 'Completado'
    }
};

export default common;
