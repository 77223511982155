import { Grid, Stack, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import { ReactComponent as BesoulIcon } from '../../../assets/icons/svg/besoul_icon.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/icons/svg/instagram_icon.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icons/svg/facebook_icon.svg';
import { ReactComponent as TikTokIcon } from '../../../assets/icons/svg/tiktok_icon.svg';
import { ReactComponent as YoutubeIcon } from '../../../assets/icons/svg/youtube_icon.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/icons/svg/twitter_icon.svg';
import CustomLink from 'ui-component/CustomLink';
import Text from 'ui-component/Text/Text';
import { useTranslation } from 'react-i18next';
import PACKAGE from '../../../../package.json';

const Footer = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box
            component={'footer'}
            // position="fixed"
            width="100%"
            minHeight="250px"
            bgcolor={theme.palette.primary.main}
            sx={{
                // bottom: 0,
                background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                [theme.breakpoints.down('md')]: {
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`
                }
            }}
        >
            {/* Desktop */}
            <Grid container height={'100%'} width={'100%'} sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
                <Grid md={3} item container alignItems="center" justifyContent="center">
                    <Box paddingTop="40px">
                        <BesoulIcon />
                    </Box>
                </Grid>
                <Grid md={3} item container justifyContent="center">
                    <Box paddingTop={'40px'}>
                        <Text
                            preset="headlineSmall"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            sx={{ xs: { marginBottom: 1 } }}
                        >
                            {t('common.footer.aboutUs')}
                        </Text>
                        {/* <CustomLink to="/contact">
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.contactUs')}
                            </Text>
                        </CustomLink> */}
                        <a href="https://sitiouno.us/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.siteOne')}
                            </Text>
                        </a>
                        <CustomLink to="pricing">
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.plans')}
                            </Text>
                        </CustomLink>
                    </Box>
                </Grid>
                <Grid md={3} item container justifyContent="center">
                    <Box paddingTop={'40px'}>
                        <Text
                            preset="headlineSmall"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            sx={{ xs: { marginBottom: 1 } }}
                        >
                            {t('common.footer.moreInformation')}
                        </Text>
                        {/* <CustomLink to="">
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.betaProgram')}
                            </Text>
                        </CustomLink> */}
                        <CustomLink to="/policy-privacy">
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.privacyPolicy')}
                            </Text>
                        </CustomLink>
                        <CustomLink to="/terms-conditions/">
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.termsAndConditions')}
                            </Text>
                        </CustomLink>
                        {/* <CustomLink to="/cookies-policies">
                            <Text
                                preset="titleMedium"
                                textColor={theme.palette.common.white}
                                fontWeight={700}
                                lineHeight={{ xs: '20px', md: '24px' }}
                            >
                                {t('common.footer.cookiesPolicy')}
                            </Text>
                        </CustomLink> */}
                    </Box>
                </Grid>
                <Grid md={3} item container justifyContent="center">
                    <Box paddingTop={'40px'} width={'70%'}>
                        <Text
                            preset="headlineSmall"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            sx={{ xs: { marginBottom: 2 } }}
                        >
                            {t('common.footer.followUs')}
                        </Text>
                        <Stack direction="row" justifyContent="space-evenly">
                            <a href="https://www.instagram.com/besoulapp/" target="_blank" rel="noreferrer">
                                <InstagramIcon />
                            </a>
                            <a href="https://www.facebook.com/BeSoulApp/" target="_blank" rel="noreferrer">
                                <FacebookIcon />
                            </a>
                            <a href="https://www.tiktok.com/@besoul.app" target="_blank" rel="noreferrer">
                                <TikTokIcon />
                            </a>
                            <a href="https://www.youtube.com/@BeSoulApp" target="_blank" rel="noreferrer">
                                <YoutubeIcon />
                            </a>
                            <a href="https://twitter.com/BeSoulApp" target="_blank" rel="noreferrer">
                                <TwitterIcon />
                            </a>
                        </Stack>
                    </Box>
                </Grid>
                <Grid md={12} height="50px" item container justifyContent="center" alignItems="end" paddingBottom="20px">
                    <Text preset="labelLarge" textColor={theme.palette.common.white} fontWeight={500}>
                        <a
                            href="https://sitiouno.us/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            {t('common.footer.copyright1')}
                        </a>
                        {` | ${PACKAGE.version} - ${t('common.footer.copyright2')}`}
                    </Text>
                </Grid>
            </Grid>

            {/* Mobile */}
            <Stack direction="column" alignItems="center" sx={(theme) => ({ [theme.breakpoints.up('md')]: { display: 'none' } })}>
                <Box paddingTop={'20px'} textAlign="center">
                    <Text preset="titleLarge" textColor={theme.palette.common.white} fontWeight={700} sx={{ xs: { marginBottom: 1 } }}>
                        {t('common.footer.moreInformation')}
                    </Text>
                    {/* <CustomLink to="">
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.betaProgram')}
                        </Text>
                    </CustomLink> */}
                    <CustomLink to="/policy-privacy">
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.privacyPolicy')}
                        </Text>
                    </CustomLink>
                    <CustomLink to="/terms-conditions">
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.termsAndConditions')}
                        </Text>
                    </CustomLink>
                    {/* <CustomLink to="/cookies-policies">
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.cookiesPolicy')}
                        </Text>
                    </CustomLink> */}
                </Box>

                <Box paddingTop={'10px'} textAlign="center">
                    <Text preset="titleLarge" textColor={theme.palette.common.white} fontWeight={700} sx={{ xs: { marginBottom: 1 } }}>
                        {t('common.footer.aboutUs')}
                    </Text>
                    {/* <CustomLink to="/contact">
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.contactUs')}
                        </Text>
                    </CustomLink> */}
                    <a href="https://sitiouno.us/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.siteOne')}
                        </Text>
                    </a>
                    <CustomLink to="/pricing">
                        <Text
                            preset="labelLarge"
                            textColor={theme.palette.common.white}
                            fontWeight={700}
                            lineHeight={{ xs: '20px', md: '24px' }}
                        >
                            {t('common.footer.plans')}
                        </Text>
                    </CustomLink>
                </Box>
                <Box paddingTop={'10px'} width={'200px'} textAlign="center">
                    <Stack direction="row" justifyContent="space-evenly">
                        <a href="https://www.instagram.com/besoulapp/" target="_blank" rel="noreferrer">
                            <InstagramIcon width="25px" />
                        </a>
                        <a href="https://www.facebook.com/BeSoulApp/" target="_blank" rel="noreferrer">
                            <FacebookIcon width="25px" />
                        </a>
                        <a href="https://www.tiktok.com/@besoul.app" target="_blank" rel="noreferrer">
                            <TikTokIcon width="25px" />
                        </a>
                        <a href="https://www.youtube.com/@BeSoulApp" target="_blank" rel="noreferrer">
                            <YoutubeIcon width="25px" />
                        </a>
                        <a href="https://twitter.com/BeSoulApp" target="_blank" rel="noreferrer">
                            <TwitterIcon width="25px" />
                        </a>
                    </Stack>
                </Box>
                <Stack direction="row" paddingTop={'10px'} alignItems="center" justifyContent="center" width="70%" marginBottom="20px">
                    <BesoulIcon width={'100px'} />
                    <Text preset="labelMedium" textColor={theme.palette.common.white} fontWeight={500} sx={{ xs: { marginLeft: 2 } }}>
                        <a
                            href="https://sitiouno.us/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            {t('common.footer.copyright1')}
                        </a>
                        {` | ${PACKAGE.version} - ${t('common.footer.copyright2')}`}
                    </Text>
                </Stack>
            </Stack>
        </Box>
    );
};

export default Footer;
