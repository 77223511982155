const home = {
    seo: {
        title: 'Be Soul: Legacy planning app',
        description:
            'Explore the beauty of sharing memories online and embark on your journey of legacy planning with our App. Create a digital legacy that lasts forever.',
        carousel: {
            altImage1: `Senior couple reliving cherished memories on a legacy planning app, sharing life's joyful moments`,
            altImage2: 'Preserve the wisdom and teachings of your ancestors with voice memos for legacy planning',
            altImage3: 'Organize and share your important documents and files within a legacy planning app before your departure'
        },
        digitalMemoriesAltImage:
            'Online memorials display featuring heartfelt tributes to a beloved dog and a cherished individual, encapsulating memories and legacy on our platform.',
        videoCarousel: {
            video1: "George shares his discovery of Be Soul: An essential app to safeguard and organize your digital legacy in today's online world",
            video2: "A Father's Guide to Leaving a Legacy with Be Soul - watch on youtube",
            video3: "A Tech Director's Journey with Be Soul - Watch on YouTube"
        }
    },
    banner: {
        title1: 'Connect with Loved Ones Through Our',
        title2: 'Legacy Planning App',
        subtitle1: 'Your legacy, your decision, your story'
    },
    welcome: {
        title: 'Plan your digital legacy and End-of-Life journey',
        subtitle: 'The app that accompanies you in the final moments of life',
        badges: {
            '1': {
                '1': 'Plan your legacy ',
                '2': 'before you leave'
            },
            '2': {
                '1': 'Commemorate ',
                '2': 'those who are no longer here'
            },
            '3': {
                '1': 'Find tools ',
                '2': 'that increase your well-being'
            }
        }
    },
    shareLegacy: {
        title: {
            '1': 'Share your legacy ',
            '2': 'with your loved ones'
        },
        subtitle:
            'Save your most precious memories in a secure digital space and share them with your loved ones on a specific date or post mortem',
        button: 'Start now'
    },
    digitalMemorials: {
        title: 'Memorials: preserving the memories of your beloved',
        label: 'Coming soon',
        subtitle: 'It is a space to create commemorative profiles of loved ones who are no longer with us',
        button: 'Download Be Soul'
    },
    testimonials: {
        title: 'Our users and their legacy planning stories',
        subtitle: 'Know the opinion of our users'
    },
    FQ: {
        title: 'Frequent asked questions',
        '1': {
            question: 'What is Be Soul App and who is it for?',
            answer: 'Be Soul is aimed at anyone who wishes to leave a lasting and meaningful legacy for their loved ones, even after they are gone. It allows you to create and share personal memories, messages and special reflections that will last over time, managing to keep alive the memory and legacy of those who use it.'
        },
        '2': {
            question: 'Is it possible to create a Be Soul profile for someone who has already passed away?',
            answer: 'Yes, it is possible to create a profile on Be Soul for someone who has already passed away. The application allows you to honor and preserve the memory of deceased loved ones by allowing the creation of profiles with their memories, messages and reflections. This gives users the opportunity to remember and share the life and legacy of the deceased person.'
        },
        '3': {
            question: 'What things can I share on Be Soul?',
            answer: 'On Be Soul you can share a variety of meaningful items, such as photographs, videos, important documents, personal memories, family stories, and cooking recipes, with people of your choice.'
        },
        '4': {
            question: 'How does the process of releasing memories assigned to my loved ones at Be Soul?',
            answer: "Be Soul works as a digital will/memorial, ensuring that all memories and content are preserved and unlocked after the person's death or on a specific date. This ensures that important memories and stories last over time, allowing loved ones to access them, find comfort and emotional connection."
        },
        '5': {
            question: 'Who can access your memories?',
            answer: 'Only people to whom you have shared the memories will be able to access them. At Be Soul, privacy and audience selection are fundamental. You can selectively choose who you want to share your memories with, ensuring that only designated people can access them, thus maintaining the confidentiality and privacy of your personal memories.'
        },
        '6': {
            question: 'What is the process for validating a death in the application?',
            answer: 'Be Soul is aimed at anyone who wishes to leave a lasting and meaningful legacy for their loved ones, even after their departure. It allows the creation and sharing of personal memories, messages, and special reflections that will endure over time, ensuring the memory and legacy of the user live on.'
        },
        '7': {
            question: 'What other functions does the app have?',
            answer: 'In addition to leaving and receiving wills to your loved ones, in Be Soul you can also access the profiles of deceased people and leave them badges with dedications, which will allow anyone who visits the profile to appreciate how valuable that person was to their loved ones. This function provides the opportunity to honor and remember those who have passed away, sharing testimonies and expressions of affection in a space dedicated to their memory.'
        }
    },
    cookies: {
        title: 'This site uses cookies to offer you a better experience. Read more about cookie policies ',
        allCookies: 'Accept ALL cookies on the site',
        analytics: 'Google Analytics',
        clarity: 'Microsoft Clarity',
        button1: 'Configure',
        button1Alternate: 'Save changes',
        button2: 'Accept all cookies'
    }
};

export default home;
