import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useLazyCheckTokenQuery } from 'api/auth.service';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [trigger] = useLazyCheckTokenQuery<any>();

    const { logout } = useAuth();
    const handleLogout = () => {
        localStorage.removeItem('jwt');
        logout();
        navigate('login', { replace: true });
    };

    useEffect(() => {
        const checkSessionHandler = async () => {
            const resCheckToken = await trigger().unwrap();
            if (!isLoggedIn || resCheckToken === 'invalidToken') {
                handleLogout();
            }
        };

        checkSessionHandler();
    }, [isLoggedIn, navigate]);

    return children;
};

export default AuthGuard;
