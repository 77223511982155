import { createApi } from '@reduxjs/toolkit/query/react';
import { httBaseQueryWithRetry } from 'services/rtk/baseQuery';

interface CreateMemorial {
    name: string;
    type: 'Third' | 'Automatic' | 'Pets' | 'Person';
}

interface CreateMemorialResponse {
    id: string;
    userId: string;
    name: string;
    type: string;
}

interface IDataPaginate {
    page?: number;
    limit?: number;
    userId?: string;
    searchBy?: string;
}

export interface UpdateMemorial {
    profilePhoto?: ProfilePhoto;
    name?: string;
    birthDate?: Date;
    placeOfBirth?: string;
    deathDate?: Date;
    placeOfDeath?: string;
    yearsOld?: string;
    phrase?: string;
    story?: string;
    files?: ProfilePhoto[];
}

export interface ProfilePhoto {
    name: string;
    originalName?: string;
    url?: string;
    size?: number;
    vector?: string;
    type: string;
    extension?: string;
    path: string;
}

interface UpdateMemorialProps {
    id: string;
    data: UpdateMemorial;
}

export interface CreateStoryAI {
    whoWasIt?: string;
    howGetToknow?: string;
    sharedMoments?: string;
    lifeChange?: string;
    howWillBeRemember?: string;
    ocupation_achievements?: string;
    hobbies?: string;
    community_contributions?: string;
    personal_anecdote?: string;
    type?: string;
    language?: string;
    name?: string;
    birthDate?: Date;
    placeOfBirth?: string;
    deathDate?: Date;
    placeOfDeath?: string;
}

export interface UpdateMemorialResponse {
    id: string;
    userId: string;
    bannerPhoto: string;
    profilePhoto: ProfilePhoto;
    photos: ProfilePhoto[];
    name: string;
    birthDate: Date | string;
    deathDate: Date | string;
    phrase: string;
    placeOfDeath: string;
    placeOfBirth: string;
    years_old: string;
    genre: string;
    verified: boolean;
    pet: object;
    landingData: object;
    isPrivate: boolean;
    type: string;
    status: string;
    story: string;
    questionary: object;
    shortQuestionary: object;
    files: ProfilePhoto[];
    signedBy: string[];
    dedicationsCount: number;
}

export interface SignedBy {
    user?: string;
    date?: Date;
    _id: string;
    name: string;
    lastName: string;
    email: string;
    profilePhoto: string;
    fullName: string;
}

export type IMemorialCreator = {
    _id: string;
    email: string;
    fullName: string;
    lastName: string;
    name: string;
    profilePhoto: string;
};

export enum EMemorialStatus {
    active = 'Active',
    inactive = 'Inactive',
    deleted = 'Deleted',
    draft = 'Draft'
}
export interface GetMemorialDataResponse {
    _id: string;
    userId: IMemorialCreator;
    name: string;
    birthDate: Date | string;
    placeOfBirth: string;
    deathDate: Date | string;
    placeOfDeath: string;
    verified: boolean;
    isPrivate: boolean;
    type: string;
    status: EMemorialStatus;
    story: string;
    signedByCount: number;
    dedicationsCount: number;
    photos: any[];
    guests: any[];
    files: any[];
    signedBy: SignedBy[];
    createdAt: Date | string;
    updatedAt: Date | string;
    profilePhoto: ProfilePhoto;
    signed: boolean;
    dedications: any[];
}

export interface ISignPayload {
    edenId: string;
    value: {
        user: string;
        date: string;
    };
}

export const memorialApi = createApi({
    reducerPath: 'memorialApi',
    baseQuery: httBaseQueryWithRetry,
    tagTypes: ['GetMemorial'],
    endpoints: (builder) => ({
        getMemorialStages: builder.query<any, void>({
            query: () => ({
                url: '/core/eden/stages',
                method: 'get'
            }),
            providesTags: ['GetMemorial']
        }),
        getMemorialRandom: builder.query<any, IDataPaginate>({
            query: (data: IDataPaginate) => ({
                url: `/core/eden/random?page=${data.page}&limit=${data.limit}${data.searchBy}`,
                method: 'get'
            })
        }),
        getMemorialPublic: builder.query<any, IDataPaginate>({
            query: (data: IDataPaginate) => ({
                url: `/core/eden/web/random?page=${data.page}&limit=${data.limit}${data.searchBy}`,
                method: 'get'
            })
        }),
        getMemorialSigned: builder.query<any, IDataPaginate>({
            query: (data: IDataPaginate) => ({
                url: `/core/eden/signed?page=${data.page}&limit=${data.limit}${data.searchBy}`,
                method: 'get'
            })
        }),
        getMemorialShared: builder.query<any, IDataPaginate>({
            query: (data: IDataPaginate) => ({
                url: `/core/eden/shared?page=${data.page}&limit=${data.limit}${data.searchBy}`,
                method: 'get'
            })
        }),
        getMemorialById: builder.query<any, { memorialId: string | undefined; isLoggedIn?: boolean }>({
            query: (data: { memorialId: string | undefined; isLoggedIn?: boolean }) => ({
                url: data.isLoggedIn ? `/core/eden/get/fulldata/${data.memorialId}` : `/core/eden/get/web/fulldata/${data.memorialId}`,
                method: 'get'
            }),
            providesTags: ['GetMemorial']
        }),
        createMemorial: builder.mutation<CreateMemorialResponse, CreateMemorial>({
            query: (data: CreateMemorial) => ({
                url: '/core/eden/create',
                method: 'post',
                data
            }),
            invalidatesTags: ['GetMemorial']
        }),
        getMyMemorials: builder.query<any, IDataPaginate>({
            query: (data: IDataPaginate) => ({
                url: `/core/eden/user/${data.userId}?page=${data.page}&limit=${data.limit}${data.searchBy}`,
                method: 'get'
            })
        }),
        updateMemorial: builder.mutation<UpdateMemorialResponse, UpdateMemorialProps>({
            query: ({ id, data }) => ({
                url: `/core/eden/update/${id}`,
                method: 'patch',
                data
            }),
            invalidatesTags: ['GetMemorial']
        }),
        activateMemorial: builder.mutation<UpdateMemorialResponse, string>({
            query: (id: string) => ({
                url: `/core/eden/activate/${id}`,
                method: 'put'
            }),
            invalidatesTags: ['GetMemorial']
        }),
        generateStoryAi: builder.mutation<any, CreateStoryAI>({
            query: (data) => ({
                url: `/core/eden/generate-story-ia/v2`,
                method: 'post',
                data
            })
        }),
        deleteMemorial: builder.mutation<any, string>({
            query: (id) => ({
                url: `/core/eden/delete/${id}`,
                method: 'delete'
            })
        }),
        signMemorial: builder.mutation<any, ISignPayload>({
            query: (data: ISignPayload) => ({
                url: `/core/eden/update-sign`,
                method: 'put',
                data
            }),
            invalidatesTags: ['GetMemorial']
        })
    })
});

export const {
    useGetMemorialStagesQuery,
    useGetMemorialSharedQuery,
    useGetMemorialByIdQuery,
    useCreateMemorialMutation,
    useGetMyMemorialsQuery,
    useLazyGetMemorialRandomQuery,
    useLazyGetMemorialPublicQuery,
    useLazyGetMemorialSharedQuery,
    useLazyGetMemorialSignedQuery,
    useLazyGetMyMemorialsQuery,
    useUpdateMemorialMutation,
    useActivateMemorialMutation,
    useGenerateStoryAiMutation,
    useDeleteMemorialMutation,
    useSignMemorialMutation
} = memorialApi;
