import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CreateElementLayout from 'layout/CreateElementLayout';

// maintenance routing
const AuthMemorials = Loadable(lazy(() => import('views/pages/AuthMemorials')));
const CreateMemorial = Loadable(lazy(() => import('views/pages/CreateMemorial')));
const DraftedMemorial = Loadable(lazy(() => import('views/pages/DraftedMemorial')));
const RandomMemorials = Loadable(lazy(() => import('views/pages/AuthMemorials/RandomMemorials')));
const SharedMemorials = Loadable(lazy(() => import('views/pages/AuthMemorials/SharedMemorials')));
const SignedMemorials = Loadable(lazy(() => import('views/pages/AuthMemorials/SignedMemorials')));
const MyMemorials = Loadable(lazy(() => import('views/pages/AuthMemorials/MyMemorials')));
const PublishedMemorial = Loadable(lazy(() => import('views/pages/AuthMemorials/PublishedMemorial')));
const PreviewMemorial = Loadable(lazy(() => import('views/pages/AuthMemorials/PreviewMemorial')));
const CreateAssistMemorial = Loadable(lazy(() => import('views/pages/CreateAssitsMemorial')));
const CreateAssistPetMemorial = Loadable(lazy(() => import('views/pages/CreateAssistsPetMemorial')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

export const AuthenticationRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/app/memorial/feed',
            element: <AuthMemorials />
        },
        {
            path: '/app/memorial/feed/randoms',
            element: <RandomMemorials />
        },
        {
            path: '/app/memorial/feed/shared',
            element: <SharedMemorials />
        },
        {
            path: '/app/memorial/feed/signed',
            element: <SignedMemorials />
        },
        {
            path: '/app/memorial/feed/my-memorials',
            element: <MyMemorials />
        }
    ]
};

export const CreateMemorialRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <CreateElementLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/app/memorial/create',
            element: <CreateMemorial />
        },
        {
            path: '/app/memorial/draft/:memorialId',
            element: <DraftedMemorial />
        },
        {
            path: '/app/memorial/create/assist-person-ai/:memorialId',
            element: <CreateAssistMemorial />
        },
        {
            path: '/app/memorial/create/assist-pet-ai/:memorialId',
            element: <CreateAssistPetMemorial />
        }
    ]
};

export const PreviewMemorialRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <Outlet />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/app/memorial/:memorialId',
            element: <PublishedMemorial />
        },
        {
            path: '/app/memorial/preview/:memorialId',
            element: <PreviewMemorial />
        }
    ]
};

export const AuthAccountRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <MainLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: [
        // {
        //     path: '/app/account/delete-account',
        //     element: <DeleteAccount />
        // }
    ]
};
