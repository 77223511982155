import { Link } from 'react-router-dom';
import GooglePlayImage from '../../assets/icons/svg/google_play_icon.svg';
import GooglePlayLogoEng from '../../assets/images/svg/play_store_logo_eng.svg';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GooglePlay = () => {
    const { i18n } = useTranslation();
    const language = i18n.language;

    return (
        <Link to="https://play.google.com/store/apps/details?id=com.sitiouno.mytestament.prod" target="_blank">
            <Box
                sx={(theme) => ({
                    height: 'max-content',
                    [theme.breakpoints.down('md')]: {
                        width: '120px'
                    },
                    [theme.breakpoints.up('md')]: {
                        width: '180px'
                    }
                })}
            >
                <img
                    src={language === 'en-US' ? GooglePlayLogoEng : GooglePlayImage}
                    alt="Download it from Google Play Store"
                    width="100%"
                />
            </Box>
        </Link>
    );
};

export default GooglePlay;
