export const deleteAccount = {
    title: 'Request for Account Deletion',
    info: {
        1: 'If you purchased your subscription via in-app purchase, you will need to cancel it manually in your app store subscription settings. Otherwise, payments will remain active and you will continue to be charged.',
        2: 'If you purchased your subscription through other means and would like to cancel it, please contact Customer Service.',
        3: 'You must include the email address linked to your digital account — we only complete requests on the information linked to your email address.',
        4: 'To verify your identity, we will send an email to the email address you provide which matches our records and wait for your response. In some instances, we may also ask for additional information to ensure we verify your identity before complying.',
        5: 'To learn more about how we handle your data, please read our',
        policyPrivacy: 'Policy Privacy.'
    },
    form: {
        name: 'Your Name',
        email: 'Account Email',
        phone: 'Contact Phone Number',
        requestDetails: 'Request Details',
        submit: 'Submit'
    },
    seo: {
        title: 'Request for Account Deletion '
    }
};
