import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import useConfig from 'hooks/useConfig';

const NotFound = () => {
    const { REACT_APP_FIREBASE_ASSETS_BASE_URL } = useConfig();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Stack width="100vw" height="100vh" alignItems="center" justifyContent="center">
            <Box
                component="img"
                height={{ xs: 'auto', sm: '100%', md: 'auto' }}
                width={{ xs: '100%', sm: 'auto', md: '100%' }}
                margin="auto"
                src={`${REACT_APP_FIREBASE_ASSETS_BASE_URL}/${isDesktop ? '404%20error%20BS_2.2' : '404-error-BS-2.4'}.webp`}
            />
        </Stack>
    );
};

export default NotFound;
