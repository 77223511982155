const memorial = {
    feed: {
        container: {
            header: {
                headerMyMemorial: 'My memorials',
                headerSigned: 'Signed',
                headerShare: 'Share with me',
                headerExplore: 'Explore',
                rightTextBtn: 'See all'
            },
            item: {
                createdBy: 'Created by:',
                signed: 'Signed {{times}} times',
                edit: 'Edit'
            }
        },
        sections: {
            page: {
                myMemorials: {
                    title: 'My memorials'
                },
                signed: {
                    title: 'Signed'
                },
                shared: {
                    title: 'Shared'
                },
                explore: {
                    title: 'Explore'
                }
            },
            filter: {
                placeholder: 'Search',
                placeholder2: 'Search a memorial',
                all: 'All',
                people: 'People',
                pets: 'Pets',
                drafts: 'Drafts'
            }
        }
    },
    view: {
        tabMemorial: 'Memorial',
        tabGallery: 'Gallery',
        inMemoryOf: 'In memory of',
        share: {
            download: 'Download',
            shareWith: 'Share memorial',
            copy: 'Copy link',
            text: 'Visit this memorial in Be soul'
        }
    }
};

export default memorial;
